import { Link } from 'react-router-dom';
import './../App.css';

function CreatorsPage() {

	const creators = ['Pirulito', 'Menganito'];

	return (
		<div className="App">
			<header className="App-header">
				<h1>Mercado Justo</h1>
				<ul>
					{ creators.map(c => 
						<li key={c}>
							<article>
								<h3><Link to={`/creadores/${c}`} className="App-link">{c}</Link></h3>
							</article>
						</li>
					)
					}
				</ul>
			</header>
		</div>
	);
}

export default CreatorsPage;
