var CryptoJS = require("crypto-js");
const {v4: uuidv4} = require("uuid");

//These are the API keys and token for generating an encrypted message
const API_KEY = "LYQNC5HjeW3OhM2smZIuwQ4JNFIFnw4G";
const API_SECRET = "18z6wWpqyGsAYvTghEq8JpM8DdX5lMY10cZZkHGq8Vh";

const generateRecurringPaymentLink = async () => {
	return fiservEncode('POST', 'https://prod.emea.api.fiservapps.com/sandbox/ipp/payments-gateway/v2/payment-url', {
			transactionAmount: {total: 42.42, currency: 'EUR'},
			transactionType: 'SALE',
			revolvingOptions: {revolvingPayment: true},
			installmentOptions: {numberOfInstallments: 6, installmentsInterest: false},
			recurringDetails: {frequency: {every: 10, unit: 'DAY'}},
			orderId: 'R-1234-1234-1234-1111',
			parentUri: 'https://www.acme.com',
			transactionNotificationURL: 'https://showmethepaymentresult.com',
			expiration: 4102358400,
			authenticateTransaction: true,
			dynamicMerchantName: 'MyWebsite',
			invoiceNumber: '96126098',
			purchaseOrderNumber: '123055342',
			ip: '264.31.73.24'
	}, async (options) => {	
		const response = await fetch(options.url, options);
		console.log(response.status);
		const response_1 = response.json();
		return response_1;
	});
};

const fiservEncode = (method, url, body, callback) => {
	const ClientRequestId = uuidv4();
	const time = new Date().getTime();

	var requestBody = JSON.stringify(body);
	if (method === 'GET') {
		requestBody = '';
	}

	const rawSignature = API_KEY + ClientRequestId + time + requestBody;
	var computedHash = CryptoJS.algo.HMAC.create(
		CryptoJS.algo.SHA256,
		API_SECRET.toString()
	);

	computedHash.update(rawSignature);
	computedHash = computedHash.finalize();
	const computedHmac = CryptoJS.enc.Base64.stringify(computedHash);

	const options = {
		method: method,
		url,
		headers: {
			"Content-Type": "application/json",
			"Client-Request-Id": ClientRequestId,
			"Api-Key": API_KEY,
			"Timestamp": time,
			"Message-Signature": computedHmac
		},
		body: JSON.stringify(body),
	};

    return callback(options);
};


export default generateRecurringPaymentLink;
