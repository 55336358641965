import { Link } from 'react-router-dom';
import './../App.css';

function HomePage() {
	return (
		<div className="App">

			<header className="App-header">
				<h1>Mercado Justo</h1>
				<p>
					Si creás contenido <Link to="/registrarse" className="App-link">registate</Link> para poder <b>recibir apoyo económico</b>.
				</p>
				<p>
					Para <b>apoyar económicamente</b> a alguien <Link to="/creadores" className="App-link">buscale acá</Link> y después vas a poder elegir cómo hacerlo.
				</p>
			</header>
		</div>
	);
}

export default HomePage;
