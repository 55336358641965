import './../App.css';

function ErrorPage() {
	return (
		<div className="App">
			<header className="App-header">
				<h1>Mercado Justo</h1>
				<p>
					La página que estás buscando no existe. Si automáticamente te mandamos acá, perdón jeje.
				</p>
			</header>
		</div>
	);
}

export default ErrorPage;
