import { useParams } from 'react-router-dom';
import './../App.css';
import generateRecurringPaymentLink from './../services/fiserv-service.js';

function CreatorPage() {
	const params = useParams();
	const saludar = () => {
		fetch("/helloworld", {
			headers: {
				"Content-Type": "application/json",
			}
		}).then(r => console.log("response", r));
	};

	const mandar = () => {
		fetch("/generate-payment-url")
			.then(resp => console.log(resp));
	};

	return (
		<div className="App">
			<header className="App-header">
				<h1>{params.creatorId}</h1>
				<p>¿Cuánta plata querés mandar mensualmente?</p>
				<p>(es por 12 meses y se puede cancelar en cualquier momento)</p>
				<select name="select">
					<option value="1000">1.000</option>
					<option value="5000" selected>5.000</option>
					<option value="10000">10.000</option>
				</select>
				<button onClick={mandar}>mandar platuqui</button>
				<button onClick={saludar}>saludar</button>
			</header>
		</div>
	);
}

export default CreatorPage;
