import './../App.css';

function RegisterPage() {

	const register = (formData) => {
		console.log(formData);
	};

	return (
		<div className="App">
			<header className="App-header">
				<h1>Mercado Justo</h1>
				<h3>Registrate</h3>
				<form action={register}>
					<input name="username" placeholder="Correo" />
					<input name="password" placeholder="Contraseña" />
					<button type="submit" className="App-button">Registrarse</button>
				</form>
			</header>
		</div>
	);
}

export default RegisterPage;
