import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import HomePage from './routes/home-page.jsx';
import ErrorPage from './routes/error-page.jsx';
import RegisterPage from './routes/register-page.jsx';
import CreatorsPage from './routes/creators-page.jsx';
import CreatorPage from './routes/creator-page.jsx';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
	{
		path: "/",
		element: <HomePage />,
		errorElement: <ErrorPage />
	},
	{
		path: "/registrarse",
		element: <RegisterPage />
	},
	{
		path: "/creadores",
		element: <CreatorsPage />
	},
	{
		path: "/creadores/:creatorId",
		element: <CreatorPage />
	}
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
